.StatsSection {
  &__level {
    // Override Bulma mobile styles
    @media screen and (max-width: 768px) {
      // Give more vertical spacing
      .level-item:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}
