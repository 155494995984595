.Features2 {
  &__feature {
    margin-bottom: 3rem;
  }

  &__icon {
    display: inline-block;
    font-size: 50px;
    width: 50px;
    height: 50px;
    margin-bottom: 1.8rem;
  }

  &__title {
    margin-bottom: 1.2rem !important;
  }
}
