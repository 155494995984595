.DashboardSection {
  &__paid-message {
    max-width: 300px;
  }

  &__image {
    margin: 0 auto;
    max-width: 370px;
  }
}
